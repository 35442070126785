.Admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.AdminInner {
    display: flex;
    flex-direction: column;
    background: #eee;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
    border-radius: 5px;
    width:400px;
}

.Title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:20px;
    background:#ddd;
    width:100%;
}

.Buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Buttons a:first-child {
    margin-left: 15px;
}

hr {
    border: 1px solid gray;
    width: 100%;
}