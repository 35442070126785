.DivEx {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0 0;
    margin-bottom:15px;
}

.List {
    display: flex;
    position: relative;
    flex-direction: column;
    list-style: square;
    margin: 0 0;
}

.NoMargin {
    margin: 0 0 !important;
}

.RootMargin {
    margin: 19px 0 !important;
}

.RootEditAllowed {
    outline: 3px dashed green;
    cursor: pointer;
}

.RootEditEnabled {
    border: 1px solid green;
    box-shadow: 2px 2px 3px black;
    left: -10px;
}

.EditAllowed {
    outline: 1px dashed green;
    cursor: pointer;
}

.EditEnabled {
    outline: 2px solid green;
    cursor: pointer;
}
