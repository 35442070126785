
.TopEditPanel {
    display: flex;
    flex-direction: row;
    position: absolute;
    background: rgba(0, 0, 0, 0);
    align-self: flex-end;
    top: -10px;
}

.TopEditPanelAccept {
    color: #ccc;
}

.TopEditPanelAcceptActive {
    color: green;
    cursor: pointer;
}

.TopEditPanelAcceptActive:hover {
    box-shadow: 2px 2px 12px black;
    border-radius: 25px;
}

.TopEditPanelAcceptActive:active {
    box-shadow: 2px 2px 6px black;
    border-radius: 25px;
}

.TopEditPanelCancel {
    color: #ccc;
}

.TopEditPanelCancelActive {
    color: red;
    cursor: pointer;
}

.TopEditPanelCancelActive:hover {
    box-shadow: 2px 2px 12px black;
    border-radius: 25px;
}

.TopEditPanelCancelActive:active {
    box-shadow: 2px 2px 6px black;
    border-radius: 25px;
}

.Warning {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -12px;
    right: -3px;
    width: max-content;
    background: rgb(205, 0, 156, 1);
    color: yellow;
    font-weight:bold;
    border-radius: 8px 2px 8px 2px;
    padding: 4px;
    align-self: flex-end;
    font-size: 12px;
}
