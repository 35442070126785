.Toggle {
    display: flex;
    flex-direction:column;
    background: #efefef;
    align-items: center;
    height: 100%;
    margin: 15px 0px;
}

.Title {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 8px;
    color: #999;
    text-shadow: -1px -1px 0px #fff, 1px 1px 0px #000;
}
