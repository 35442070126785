.Text {
}

.EditAllowed {
    cursor: pointer !important;
    border-bottom: 1px dotted green;
}

.EditEnabled {
    border-bottom: 2px solid green;
}

.Bold {
    font-weight: bold;
}

@keyframes glow {
    from {
        color: rgba(0, 0, 0, 0.5);
    }

    to {
        color: rgba(0, 0, 0, 1);
    }
}