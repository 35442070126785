.Edit {
    position: absolute !important;
    width: 60%;
    z-index: 1;
}

.EditInner {
    padding: 10px 0px;
    height: auto;
    background-color: white;
    margin: 5px 15px;
    border-radius: 5px;
}


.Inputs {
    width:100%;
    margin: 15px;
}

.box {

}

.shadow1 h3, .shadow2 h3, .shadow3 h3, .shadow4 h3, .shadow5 h3, .shadow6 h3, .shadow7 h3, .shadow8 h3 {
    width: 87%;
    height: 100px;
    margin-left: 6%;
    border: 2px dashed #F7EEEE;
    border-radius: 5px;
}

.shadow4:before, .shadow4:after {
    position: absolute;
    content: "";
    top: 14px;
    bottom: 14px;
    left: 0;
    right: 0;
    box-shadow: 0 0 25px 3px #548E7F;
    border-radius: 100px/10px;
    z-index: -1;
}
.shadow1, .shadow2, .shadow3, .shadow4, .shadow5, .shadow6, .shadow7, .shadow8 {
    position: relative;
}

.shadow1, .shadow2, .shadow3, .shadow4, .shadow5, .shadow6, .shadow7, .shadow8 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}

.box4 {
    background-color: #9EEBBF;
}

.Buttons {
    display: flex;
    flex-direction: row;
    width:100%;
}

.MainButtons {
    display: flex;
    flex: 3 0 0;
    justify-content: center;
}

.IconButtons {
    display: flex;
    flex: 1 0 0;
    justify-content:flex-end;
    padding-right:10px;
}

.SlidingButton {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    align-items: center;
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.17, 0.04, 0.03, 0.94);
    width: 20px;
    padding: 0px 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.SlidingButton:hover {
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color:pink;
    width: 100px;
}

.SlidingButton:active {
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, .3);
}

.SlidingButtonInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0px;
    height: 30px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transition-duration: 2s;
    border: none;
}

.SlidingButton:hover div {
    width: 90px;
}

.SlidingButton:hover div span {
    display:flex;
}

.SlidingButtonInner span {
    display: none;
    color: rgba(0, 0, 0, 0.6)
}

.Delete {
    display: flex;
    color: red;
}

