/* A simple, css only, (some-what) responsive menu */

.Menu {
    background: #fff;
    font-family: helvetica, arial, serif;
    font-size: 13px;
    text-align: center;
    display: inline-block;
    box-shadow: 0 0 70px #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.Logo {
    width: 330px;
    min-width: 330px;
    height: 120px;
}

/* a little "umph" */
.MenuDecor {
    background: #6EAF8D;
    background: linear-gradient('to left', white 50%, #6EAF8D 50%);
    background-size: 50px 25%;
    padding: 2px;
    display: block;
}

.Menu a {
    text-decoration: none;
    color: #000;
    display: block;
}

.Menu ul {
    list-style: none;
    position: relative;
    text-align: left;
}

.Menu ul li {
    float: left;
}

/* clear'n floats */
.Menu ul:after {
    clear: both;
}

.Menu ul:before, ul:after {
    content: " ";
    display: table;
}

.MenuInner {
    position: relative;
    background: #fff;
    text-align: center;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px #0E0E0E;
    box-shadow: 2px 2px 3px #888;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 100%;
    height: 70px;
    border-top: 1px solid black;
    border-left: 1px solid black;   
}

.MenuRight {
    width: 200px;
    background-color: red;
    margin-left: auto;
}

.NotClickable > a {
    cursor: default;
}

/* prime */
.Primary li {
    width: 170px;
}

.Primary li a {
    display: block;
    padding: 20px 30px;
    border-right: 1px solid #3D3D3D;
}

.Primary li:last-child a {
    border-right: none;
}

.Primary li a:hover {
    color: #ccc;
}

/* subs */
.SubMenu {
    position: absolute !important;
    z-index: 200;
    box-shadow: 2px 2px 0 #BEBEBE;
    width: 170px;
    display: none;
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    outline: 0;
    height: 10px;
}

.SubMenu li {
    float: none;
    margin: 0;
    width: 100%;
}

.SubMenu li a {
    border-bottom: 1px dotted #ccc;
    border-right: none;
    color: #000;
    padding: 15px 30px;
    width: 100%;
}

.SubMenu li:last-child a {
    border-bottom: none;
}

.SubMenu li a:hover {
    color: #000 !important;
    background: #eeeeee !important;
}

/* sub display*/
.Primary li:hover ul {
    display: block;
    background: #fff;
}

/* keeps the tab background white */
.Primary li:hover a {
    background: #ccc;
    color: #666;
    text-shadow: none;
}

.Primary li:hover a {
    color: #000;
    position:relative;
    border: 1px solid gray;
    border-radius: 3px;
}
