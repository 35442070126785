.LanguageSelector {
    padding: 7px 7px;
    background-color: #ADA3A1;
}
.LanguageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.LanguageContainer > div img {
    width: 24px;
    height: 12px;
}

.LanguageContainer > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #3D3D3D;
    width: 40px;
    height: 24px;
}

.last {
    border-right: none !important;
}

.active img {
    width: 40px !important;
    height: 24px !important;
}