.About {
    width: 1000px;
    padding: 0px 20px;
}

.List {
    list-style: square;
}

.iframeContainer {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
}

.iframe {
    width: 400px;
    height: 300px;
}

.ContactContainer {
    background: url(../../resources/images/tuchbg.jpg) no-repeat top center;
    background-size: cover;
    width: 100%;
    height: 500px;
}

.ServerName {
    font-size: 3.125rem;
    color: #223d61;
    text-align: center;
}