.Button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #000;
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    width: max-content;
    min-width: 80px;
}

.Button:focus {
    outline: none;
}

.Button:active {
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, .3);
}

.ButtonDisabled {
    background: #ccc !important;
    color: #000 !important;
    cursor:not-allowed !important;
}

.error {
    background: rgba(240, 87, 108, 1);
}

.error:hover {
    background: rgba(240, 87, 108, 0.9);
}

.success {
    background: rgba(161, 240, 69, 1);
}

.success:hover {
    background: rgba(161, 240, 69, 0.8);
}

.primary {
    background: rgba(40, 132, 246, 1);
    color: #fff;
}

.primary:hover {
    background: rgba(40, 132, 246, 0.9);
    color: #fff;
}

.warning {
    background: rgba(222, 135, 40, 1);
}

.warning:hover {
    background: rgba(222, 135, 40, 0.9);
}