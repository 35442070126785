.Demo {
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
}

.DemoNotification {
    background-color:antiquewhite;
    border: 1px solid black;
    border-radius: 8px;
    padding: 4px;
    margin: 10px 10px 0px 10px;
}