.ToggleButton {
    display: inline-block;
    border: 1px solid #000;
    padding: 10px;
    text-align: center;
    background: #ccc;
    color: #000;
    text-decoration:none;
    width: 40px;
}

.ToggleButton.ON.active {
    background: rgba(0, 255, 0, 0.4);
}

.ToggleButton.OFF.active {
    background: rgba(255, 0, 0, 0.4);
}

.ToggleButton.left {
    border-radius: 8px 0px 0px 8px;
}

.ToggleButton.right {
    border-radius: 0px 8px 8px 0px;
}