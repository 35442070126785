.AddNewItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    height: 20px;
    position: relative;
    top: 2px;
    border: 1px solid green;
    cursor: pointer;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    color: green;
    margin: 4px 0px;
}

.AddNewItem:hover {
    background: rgba(0, 255, 0, .3);
}

.AddNewItem:active {
    background: rgba(0, 255, 0, .3);
    box-shadow: inset 2px 2px 1px rgba(0, 255, 0, .8);
}

.AddButton {
    color: green;
    margin: 3px;
}

.ButtonTitle {
    padding-left: 3px;
    margin-right: 15px;
}