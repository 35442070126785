.Loading {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
}

.Loaded {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: 0px;
    height: 0px;
    background-color: rgba(255, 255, 255, 1);

    animation-name: fadeBackdrop;
    animation-duration: 2s;
}

.LoadingLogo {
    width: 660px;
    height: 240px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 101;
    transform: translate(-50%, -70%);
}

.LoadedLogo {
    width: 330px;
    height: 120px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;

    animation-name: moveLogo;
    animation-duration: 2s;
}

.CompleteLoadingLogo {
    width: 330px;
    height: 120px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
}

@keyframes moveLogo {
    from {
        width: 660px;
        height: 240px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 101;
        transform: translate(-50%, -70%);
    }

    to {
        width: 330px;
        height: 120px;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 100;
    }
}

@keyframes fadeBackdrop {
    0% {
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        background-color: rgba(255, 255, 255, 1);
    }

    60% {
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        background-color: rgba(255, 255, 255, 1);
    }

    100% {
        position: absolute;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        background-color: rgba(255, 255, 255, 0);
    }
}

.LoadingText {
    position: absolute;
    display: inline-block;
    color: #ADA3A1;
    border: 2px solid black;
    border-radius: 5px;
    padding: 3px 40px;
    z-index: 101;
    left: 50%;
    top: 60%;
    z-index: 102;
    transform: translate(-50%, -50%);
}

.LoadingTextHidden {
    display: none;
}

.ldsEllipsis {
    position: absolute;
    display: inline-block;
    width: 64px;
    height: 64px;
    z-index: 101;
    left: 50%;
    top: 60%;
    z-index: 102;
    transform: translate(-110%, 0px);
}

.ldsEllipsisHidden {
    display: none;
}

.ldsEllipsis div {
    position: absolute;
    top: 27px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #ADA3A1;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
    left: 0px;
    animation: ldsEllipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
    left: 0px;
    animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
    left: 56px;
    animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
    left: 122px;
    animation: ldsEllipsis3 0.6s infinite;
}

@keyframes ldsEllipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ldsEllipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes ldsEllipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(65px, 0);
    }
}
